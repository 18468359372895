import * as React from 'react'
// import Layout from '../components/layout'
import Seo from '../../components/seo'

const BlogPost = () => {
  return (
    <div>
      <p>Apartment contents will go here (eventually).</p>
    </div>
  )
}

export const Head = () => <Seo title="Super Cool Blog Posts" />

export default BlogPost